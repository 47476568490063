import http from '@/http/axios';
import common from './common';

async function getTransactionLogsApi(query) {
  const res = await http.get(`/api/TransactionLogs?`
  + `${!query.paging ? '' : `paging=${query.paging}`}`
  +  `${!query.pageToken ? '' : `&pageToken=${query.pageToken}`}`
  +  `${!query.tenantId ? '' : `&tenantId=${query.tenantId}`}`
  +  `${query.types < 0 ? '' : `&types=${query.types}`}`
  +  `${!query.start ? '' : `&start=${query.start}`}`
  +  `${!query.end ? '' : `&end=${query.end}`}`);
  return common.haveSuccess(res);
}

export default {
  getTransactionLogsApi,
};
