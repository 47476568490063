import http from '@/http/axios';
import common from './common';

async function getTenantsApi(hasEnable) {
  let query = '';
  if (hasEnable) {
    query = `?hasEnable=${hasEnable}`;
  }
  const res = await http.get(`/api/Tenants${query}`);
  return common.haveSuccess(res);
}

async function createTenantsApi(data) {
  const res = await http.post('/api/Tenants', data);
  return common.haveSuccess(res);
}

async function updateTenantsApi(id, data) {
  const res = await http.put(`/api/Tenants/${id}`, data);
  return common.haveSuccess(res);
}

async function getDomainsApi(id) {
  const res = await http.get(`/api/Tenants/${id}/Domains`);
  return common.haveSuccess(res);
}

async function createDomainsApi(data) {
  const res = await http.post('/api/Tenants/Domain', data);
  return common.haveSuccess(res);
}

async function deleteDomainsApi(data) {
  const res = await http.post('/api/Tenants/DeleteDomain', data);
  return common.haveSuccess(res);
}

async function createCreditApi(id, data) {
  const res = await http.post(`/api/Tenants/${id}/Credit`, data);
  return common.haveSuccess(res);
}

async function tenantInitApi(id) {
  const res = await http.post(`/api/Tenants/${id}/Init`);
  return common.haveSuccess(res);
}

async function getSystemConfigApi(id) {
  const res = await http.get(`/api/Tenants/${id}/SiteConfigs`);
  return common.haveSuccess(res);
}

async function createSystemConfigApi(id, data) {
  const res = await http.post(`/api/Tenants/${id}/SiteConfigs`, data);
  return common.haveSuccess(res);
}

async function RefreshUserRolesApi(id) {
  const res = await http.post(`/api/Tenants/${id}/RefreshUserRoles`);
  return common.haveSuccess(res);
}



export default {
  getTenantsApi,
  createTenantsApi,
  updateTenantsApi,
  getDomainsApi,
  createDomainsApi,
  deleteDomainsApi,
  createCreditApi,
  tenantInitApi,
  getSystemConfigApi,
  createSystemConfigApi,
  RefreshUserRolesApi,
};
