<template>
  <v-data-table
    hide-default-footer
    :headers="headers"
    :items="transactionLogsList"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-col
        class="d-flex"
        cols="6"
        sm="6"
      >
        <label class="mt-5 mr-4">帐变类型: </label>
        <v-select
          v-model="type"
          :items="transactionType"
        ></v-select>
      </v-col>
      <dateTime :title="'时间'" :showTime="false" :showbtn="true" v-on:search="search"></dateTime>
      <v-toolbar flat>
        <v-toolbar-title class="text-h3 font-weight-bold"
          >帐变记录 列表</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <template v-slot:footer>
      <div class="container text-center mt-3">
        <a v-if="page > 1 && totalPages > 0" class="text-secondary pl-2"
          href="javascript:void(0);"
          v-on:click="doPaging('prev')">上一页</a>
        <span v-else class="text-secondary pl-2">上一页</span>
        <span class="text-primary">
          {{totalRecords > 0 ? page : 0}}/{{totalPages}}
          (合计{{totalRecords}}条记录)
        </span>
        <a v-if="page < totalPages"
          class="text-secondary pl-2" href="javascript:void(0);"
          v-on:click="doPaging('next')">下一页</a>
        <span v-else class="text-secondary pl-2">下一页</span>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import TransactionLogs from '@/apis/TransactionLogs';
import dateTime from '@/components/dateTime.vue';
import mixinDateAndTime from '@/assets/js/console';
import filter from '@/filters/index';

export default {
  name: 'tenantListView',
  props: ['tenantId'],
  components: {
    dateTime,
  },
  data: () => ({
    headers: [
      {
        text: 'Id',
        align: 'start',
        sortable: false,
        value: 'id',
        class: 'text-h4 font-weight-bold primary--text',
      },
      {
        text: '站台Id',
        value: 'tenantId',
        class: 'text-h4 font-weight-bold primary--text',
      },
      {
        text: '类型',
        value: 'transactionType',
        class: 'text-h4 font-weight-bold primary--text',
      },
      {
        text: '金额',
        value: 'amount',
        class: 'text-h4 font-weight-bold primary--text',
      },
      {
        text: '初始金额',
        value: 'originalBalance',
        class: 'text-h4 font-weight-bold primary--text',
        sortable: false,
      },
      {
        text: '最新金额',
        value: 'newBalance',
        class: 'text-h4 font-weight-bold primary--text',
        sortable: false,
      },
      {
        text: '操作员Id',
        value: 'operatorId',
        class: 'text-h4 font-weight-bold primary--text',
        sortable: false,
      },
      {
        text: '时间',
        value: 'timestamp',
        class: 'text-h4 font-weight-bold primary--text',
        sortable: false,
      },
      {
        text: '备注',
        value: 'memo',
        class: 'text-h4 font-weight-bold primary--text',
        sortable: false,
      },
    ],
    type: '所有',
    transactionType: ['所有', '手动加减分', '流量费用'],
    transactionLogsList: [],
    totalPages: 0,
    page: 0,
    totalRecords: 0,
    pageToken: '',
    summary: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  }),

  methods: {
    async getTransactionLogs(query) {
      const res = await TransactionLogs.getTransactionLogsApi(query);
      if (res.data.result !== null) {
        this.transactionLogsList = res.data.result.records;
        this.totalRecords = res.data.result.totalRecords;
        this.pageToken = res.data.result.pageToken;
        this.totalPages = res.data.result.totalPages;
        this.page = res.data.result.page;
        this.summary = res.data.result.summary;
        this.transactionLogsList.forEach((value, index) => {
          this.transactionLogsList[index].timestamp = filter.formatDts(value.timestamp);
        });
      }
    },

    doPaging(paging) {
      const queryData = {
        paging: paging,
        pageToken: this.pageToken,
        tenantId: this.tenantId,
        types: this.transactionType.indexOf(this.type) - 1,
        start: this.fullStartDateTime,
        end: this.fullEndDateTime,
      };
      this.getTransactionLogs(queryData);
    },

    doSearch() {
      const queryData = {
        tenantId: this.tenantId,
        types: this.transactionType.indexOf(this.type) - 1,
        start: `${this.startDate} 00:00`,
        end: `${this.endDate} 00:00`,
      };
      this.getTransactionLogs(queryData);
    },

    search(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.endDate = value.endDate;
      this.endTime = value.endTime;
      this.doSearch();
    },
  },
  mixins: [mixinDateAndTime],
};
</script>
